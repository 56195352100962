@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'font-awesome/css/font-awesome.min.css';
@import '@fortawesome/fontawesome-free/css/all.min.css'; 
@import './css/dataTables.bootstrap4.min.css';

:root {
  --yellow-color: #f3ec17;
  --violet-color: #7E39E0;
  --green-color: #27c04c;
  --red-color: #f84450;
  --white: #fff;
  --gray-color-1: #ccc;
  --gray-color-2: #ededed;
  --black: #000;
  --pink-gradient: linear-gradient(to bottom, #e61f71, #5b52a2);
}
p {
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

a,
a:hover {
  text-decoration: none;
}
.btn-check:focus+.btn, .btn:focus {
    box-shadow: none !important;
}
.row {
  margin: 0;
}

.btn-violet {
  background: var(--violet-color);
  border-color: var(--violet-color);
}

.btn-green {
  background: var(--green-color);
  border-color: var(--green-color);
}

.btn-red {
  background: var(--red-color);
  border-color: var(--red-color);
}

.text-violet {
  /* color: var(--violet-color); */
}

.text-green {
  color: var(--green-color);
}

.text-red {
  color: var(--red-color);
}

.header {
  padding: 50px 0;
  /*background: var(--black);*/
  background: url(/src/img/bg1.png), #000;
}
.header__content--block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.navigation {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  background: var(--gray-color-2);
  z-index: 100;
}
.navigation i {
  font-size: 22px;
}
.headerText {
  color: var(--white);
  float: right;
  padding: 12px 0;
}
.headerText p {
  font-size: 18px;
  padding-bottom: 12px;
}
.headerText p span {
  font-size: 20px;
}
.navigation ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: -7px;
  margin-top: -10px;
}

.navigation a {
  color: inherit;
}

.navigation .active p {
  color: var(--green-color);
}
.nav-pills {
  background: #fff;
  /*background-image: linear-gradient(to top, #6e3f1c, #926839, #b6955a, #d8c380, #f9f4ab);*/
}
.nav-pills li:first-child {
  border-left: transparent !important;
}
.nav-pills li:last-child {
  border-right: transparent !important;
}

.container{
  max-width: 1400px;
}
/* TAB STYLES */
#gift-event.nav-pills {
  justify-content: space-evenly;
}

#gift-event.nav-pills .nav-item {
  flex: 1;
  text-align: center;
  border: 2px solid var(--white);
  border-top: transparent;
}
#gift-event.nav-pills .nav-item .nav-link {
  background-image: linear-gradient(to top, #6e3f1c, #926839, #b6955a, #d8c380, #f9f4ab);
}

#gift-event.nav-pills .nav-link {
  font-weight: 500;
  color: #212529;
  border-radius: 0;
  font-size: 18px;
  padding: 10px 0;
}

#gift-event.nav-pills .nav-link.active {
  background: #0000FE;
  color: var(--white);
  border: transparent !important;
}

#gift-eventContent.tab-content {
  /*padding: 0 30px;*/
}

/* COUNTDOWN STYLE */
.countdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.countdown p b {
  font-size: 18px;
}

.countdown p span {
  padding: 2px 10px;
  margin-top: 100px;
  border-radius: 3px;
  background: var(--gray-color-2);
  font-weight: 700;
  font-size: 20px;
}

/* JOIN GAME STYLE */
.disabledMinus{
  pointer-events: none;
  opacity: 0.4;
}

.join-game {
  padding-bottom: 20px;
  /*border-bottom: 2px solid var(--gray-color-1);*/
}

.join-game--btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.join-game--btns a {
    width: 140px;
    padding: 7px 20px;
    margin: 0 20px;
    font-size: 18px;
}

.game-count {
  width: 90%;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.game-count p {
  font-size: 24px;
  font-weight: 600;
  color: var(--white);
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  border-radius: 50%;
}

.game-count-1 p:nth-child(3),
.game-count-1 p:nth-child(5),
.game-count-2 p:nth-child(2),
.game-count-2 p:nth-child(4) {
  background: var(--red-color);
}

.game-count-1 p:nth-child(2),
.game-count-1 p:nth-child(4),
.game-count-2 p:nth-child(3),
.game-count-2 p:nth-child(5) {
  background: var(--green-color);
}

.game-count p:first-child {
  background: var(--red-color)
}

.game-count:nth-child(3) p:first-child {
  background: var(--green-color);
}

/* GOLD RECORD */
.gold-record {
  margin: 20px 0 100px;
  padding-top: 40px;
}
.gold-record-table {
  /* border: 1px solid #eee; */
}
.gold-record h4 {
  padding: 5% 0 2% 0;
}
#gift-eventContent table thead {
  color: var(--white);
  background: linear-gradient(to right, #e61f71, #5b52a2);
}

#gift-eventContent table th,
#gift-eventContent table td {
  text-align: center;
}

#gift-eventContent table th:first-child,
#gift-eventContent table td:first-child {
  text-align: left;
}

#gift-eventContent table th {
  font-weight: 400;
}

#gift-eventContent table td {
  vertical-align: middle;
}

.result-number {
  font-size: 16px;
  font-weight: 600;
}

.result-circle {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  border-radius: 50%;
}

.app--sidebar {
  background: var(--pink-gradient);
}

.menu-list {
  color: var(--white) !important;
  padding: 15px;
}

.menu-list:hover {
  background: var(--violet-color) !important;
}

.menu-item i {
  margin-right: 10px;
}

.accordion-button:not(.collapsed) {
  color: var(--white) !important;
  background-color: var(--violet-color) !important;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: url(/src/img/accordion-dropdown-icon.svg);
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-item:last-child {
  margin-bottom: 100px;
}

.accordion-body {
  color: var(--white);
}

@media screen and (max-width: 1024px) {
  .game-count {
    /*width: 70%;*/
  }
  .border-form{
    border: 1px solid grey;
    margin: auto;
    width: 80%;
    padding: 30px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 600px) {
  .border-form{
    border: 1px solid grey;
    margin: auto;
    width: 80%;
    padding: 30px;
    border-radius: 4px;
  }
  .header {
    padding: 40px 0;
    text-align: center;
  }
  .header img {
    width: 80%;
  }
  .headerText {
    float: initial;
    text-align: center;
  }
  .app {
    min-height: max-content;
    margin-bottom: 103px;
  }

  .header__content--block img {
    width: 100%;
    height: auto;
  }

  #gift-eventContent.tab-content {
    /*padding: 0 10px;*/
  }  
  .join-game--btns a {
    font-size: 15px;
    width: 33.33%;
    padding: 5px 10px;
    margin: 0 5px;
  }

  .game-count {
    width: 95%;
  }

  .game-count p {
    width: 50px;
    height: 50px;
    margin: 0;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 318px) {
  .border-form{
    border: 1px solid grey;
    margin: auto;
    width: 80%;
    padding: 30px;
    border-radius: 4px;
  }
  #gift-event.nav-pills .nav-link {
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .border-form{
    border: 1px solid grey;
    margin: auto;
    width: 80%;
    padding: 30px;
    border-radius: 4px;
  }
  .nav-link {
    padding: .5rem;
  }
  .countdown p b {
    font-size: 15px;
  }
  #gift-event.nav-pills .nav-link {
    font-size: 14px;
  }
  .countdown p b span {
    padding: 5px;
  }

  .join-game--btns a {
    padding: 4px;
    font-size: 14px;
  }

  .game-count p {
    width: 35px;
    height: 35px;
  }

  .game-count p {
    font-size: 18px;
  }
  .gold-record h4 {
    padding: 0% 0 2% 0;
  }
  td,
  th {
    font-size: 14px;
  }
}
@media screen and (max-width: 698px) {
  .border-form-login, .border-form {
    width: 85% !important;
  }
}
@media screen and (min-width: 698px) and (max-width: 968px) {
  .border-form{
    border: 1px solid grey;
    margin: auto;
    width: 80%;
    padding: 30px;
    border-radius: 4px;
  }
  .join-game--btns a {
    width: 125px;
    padding: 8px 0px;
    margin: 0 5px;
    font-size: 14px;
  }
  .game-count {
    width: 100%;
  }
  .game-count p {
    font-size: 22px;
    font-weight: 600;
    color: var(--white);
    width: 50px;
    height: 50px;
    margin: 5px;
    padding: 25px;
  }
}

.Card :hover{
  color:blue
}

.card{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.forms{
  width: 100%;
    /* height: 66px; */
    border-radius: 4px;
    border: 1px solid grey;
    padding: 10px 10px 11px 10px;
    text-align: center;
}

.formswith{
  width: 100%;
  /* height: 66px; */
  border-radius: 4px;
  border: 1px solid grey;
  padding: 10px 10px 11px 10px;
  text-align: center;
  /* margin-left: 13%; */
}

.btn-block{
  /* width: 144px; */
  padding: 4px 4px 7px 4px;
  border: 0;
  background-image: linear-gradient(to top, #6e3f1c, #926839, #b6955a, #d8c380, #f9f4ab);
}

.withdrawBtn{
  /* border: 1px solid grey; */
  margin: auto;
  width: 42%;
  padding: 30px;
  border-radius: 4px;
  margin-top: 6%;
}
.withBtn{
  width: 100% !important;
}

.border-form{
  border: 1px solid grey;
  margin: auto;
  width: 42%;
  padding: 30px;
  border-radius: 4px;
  margin-top: 6%;
}

.border-form-login{
  border: 1px solid grey;
  margin: auto;
  width: 42%;
  padding: 57px;
  border-radius: 4px;
  margin-top: 6%;
}

.btn-main-login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*padding-top: 30px;*/
}

.btn-main{
  display: flex;
  flex-direction: column;
    justify-content: center;
    padding-top: 30px;
}

.btn-main-dep{
   display: flex;
   justify-content: center;
   padding-top: 30px;
}

.reg-text{
  text-align: center;
    /* margin-bottom: 36px; */
}

.label-text{
  display: flex;
    justify-content: center;
    font-size: 17px;
}


.money{
  padding: 12px 0px;
  width: 28%;
  margin: 7px 13px 7px 0;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #C3C3C3;
  color: #000;
  cursor:pointer
}

.main{
  margin-top: 51px;
    /* width: 100%; */
    margin-left: 30px;
    margin-right: 30px;
}

.main_next{
  display:flex;
  justify-content: center;
}

.main-style{
  margin-bottom: 10% !important;
}

.notify{
    /* font-size: 14px; */
    font-family: unset;
    /* background: #66f0ec; */
    padding-left: 10 10 1;
    padding: 10 10 10 10;
    padding: 10px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 16px;
}

.moneyGame{
  padding: 12px 0px;
  width: 10.6%;
  margin: 7px 7px 7px 0;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #C3C3C3;
  color: #000;
  cursor: pointer;
  height: 35px;
  background: #C3C3C3;
}

.align{
  display:flex;
  margin-top: 18px;
}

.btnI{
  padding: 0px 9px 3px 9px;
  border:none;
}

.btnID{
  padding: 0px 12px 3px 12px;
  border:none;
}

.main_nextID{
  display: flex;
  margin-left:31px ;
}

.inputne{
  width: 106px !important;
    text-align: center;
}

.amountValue{
  margin-left: 35px;
  font-weight: 500;
  font-size: 17px;
}
.footer-btn{
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: inherit;
}

.select-no{
  cursor: pointer;
}

.moneyGameDep{
  padding: 12px 0px;
  width: 100%;
  /* margin: 7px 13px 7px 0; */
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #C3C3C3;
  color: #000;
  cursor: pointer;
  height: 35px;
  background: #C3C3C3;
  margin-top: -3px;
  margin-left: 21px;
}

.tran-head{
  color: var(--white);
    background: linear-gradient(to right, #e61f71, #5b52a2);
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.datedata{
  display: none;
}

.timer{
  margin-top: -9px;
}

.btn-with{
    margin-left: 8px;
    background: #500ea8;
    border-color: #500ea8;
}

.tronText{
    font-size: 17px;
    font-weight: 500;
}

.numberZero{
 background-image: linear-gradient(to top, #7E39E0, #F84450)
}

.numberFive{
  background-image: linear-gradient(to top, #7E39E0, #27C04C)
}

.periodText{
  font-size: 17px;
    font-weight: 500;
    color: darkslategrey;
}

.periodDtata{
  font-size: 22px;
}

.giftFirstText{
  font-size: 17px;
    font-weight: 00;
    color: #414040;
    font-weight: 500;
    margin-top: 10px;
}

.maintabs{
  
  display: flex;
  justify-content: space-around;
  margin-top: 13px;
}
.pay-option-label {
  width: 100%;
  background: #e6effb;
  border: 2px solid #e6effb;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-align: center;
  margin-bottom: 15px;
}
.pay-option-label:after {
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #2c80ff;
  content: "";
  top: 0;
  right: -5px;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}
/* .pay-option-check {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0;
} */
.pay-option-check:checked ~ label {
  border-color: #2c80ff;
}
.pay-option-check:checked ~ label:after {
  transform: translate(-50%, -50%) scale(1);
}

.input-checkbox,
.input-radio {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px;
}
.input-checkbox ~ label:not(.error),
.input-radio ~ label:not(.error) {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 0;
  color: #495463;
  padding-left: 42px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  text-transform: capitalize;
  display: block;
}
.input-checkbox ~ label:not(.error):before,
.input-checkbox ~ label:not(.error):after,
.input-radio ~ label:not(.error):before,
.input-radio ~ label:not(.error):after {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  transition: all 0.4s;
}
.input-checkbox ~ label:not(.error):before,
.input-radio ~ label:not(.error):before {
  content: "";
  border: 2px solid #d2dde9;
}
.input-checkbox ~ label:not(.error):after,
.input-radio ~ label:not(.error):after {
  line-height: 30px;
  text-align: center;
  font-family: themify;
  content: "\e64c";
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  background: #2c80ff;
  opacity: 0;
}
.input-checkbox:disabled ~ label:not(.error),
.input-radio:disabled ~ label:not(.error) {
  opacity: 0.5;
}
.input-checkbox:checked ~ label:not(.error):after,
.input-radio:checked ~ label:not(.error):after {
  opacity: 1;
}
.input-checkbox ~ .error,
.input-radio ~ .error {
  position: absolute;
  left: 1px;
  color: #fff;
  font-size: 12px;
  bottom: 100%;
  background: #ff6868;
  padding: 5px 10px;
  z-index: 1;
  border-radius: 2px;
  white-space: nowrap;
}


/* Data Table Start */
.resultTable thead tr {
  color: var(--white);
  background: linear-gradient(to right, #e61f71, #5b52a2);
}
.resultTable .sorting::before, .resultTable .sorting::after {
  display: none !important;
}
.resultTable .sorting_asc::before, .resultTable .sorting_asc::after {
  display: none !important;
}
.resultTable .sorting_desc::before, .resultTable .sorting_desc::after {
  display: none !important;
}
.resultTable .table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: transparent;
  color: var(--bs-table-striped-color);
  border-bottom: 1px solid #eee;
}
div.dataTables_wrapper div.dataTables_info {
  float: left;
  padding-top: 0.5em;
  font-size: 14px;
}
div.dataTables_wrapper div.dataTables_paginate {
  float: right;
  padding-top: 0.5em;
  font-size: 14px;
}
.dataTables_paginate a {
color: #212529;
}
.dataTables_paginate span {
color: #0d6efd;
padding: 0 10px;
}
@media screen and (min-width: 698px) and (max-width: 968px) { 
.resultTable table td, .resultTable table th, .resultTable table td p{
  font-size: 13px;
}
.result-circle {
  width: 11px;
  height: 11px;
}
div.dataTables_wrapper div.dataTables_info {
  font-size: 12px;
}
div.dataTables_wrapper div.dataTables_paginate {
  font-size: 12px;
}
}
/* Data Table End */

.pagination-main{
  display:flex;
  justify-content: space-between;
}

.prev-next{
  display: flex;
  justify-content: flex-end;
}

.total-page{
  font-weight: 500;
  margin-right: 24px;
  color: grey;
}

.user-prev-next{
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
  /* padding-bottom: 92px; */
  /* margin-left: 36px; */
  /* padding: 24px; */
}

.top{
  margin-top: -16px;
  font-size: 19px;
}

.gamename-text{
text-transform: capitalize;
}

.rules-data{
  font-size: 16px;
    font-weight: 500;
    color: black;
    line-height: normal;
}

.rules-heading{
  font-size: 20px;
  font-weight: 500;
  color: black;
  margin-bottom: 20px;
}


.switch-container {
	position: absolute;
	top: 50%; left: 50%;
	-webkit-transform: translate3d( -50%, -50%, 0 );
	transform: translate3d( -50%, -50%, 0 );
}

input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 40px;
	height: 20px;
	border-radius: 999px;
	background-color: #00B0F2;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch + div span {
	position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child( 1 ) {
    margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
	width: 94px;
	background-position: 0 0;
	background-color: #3b89ec;
}

input[type="checkbox"].switch + div {
	width: 94px;
	height: 48px;
}


input[type="checkbox"].switch:checked + div {
	background-color: #57BB00;
}

input[type="checkbox"].switch + div > div {
	float: left;
	width: 46px; height: 46px;
	border-radius: inherit;
	background: #ffffff;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
	margin-top: 1px;
	margin-left: 1px;
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(20px, 0, 0);
	transform: translate3d(20px, 0, 0);
	background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 24px;
	height: 24px;
	margin-top: 1px;
}


input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(46px, 0, 0);
	transform: translate3d(46px, 0, 0);
}

.promo-text{
  padding: 35px;
    display: flex;
    justify-content: center;
}


:root {
  --yellow-color: #f3ec17;
  --violet-color: #7E39E0;
  --green-color: #27c04c;
  --red-color: #f84450;
  --white: #fff;
  --gray-color-1: #ccc;
  --gray-color-2: #ededed;
  --black: #000;
  --pink-gradient: linear-gradient(to bottom, #e61f71, #5b52a2);
}
p {
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

a,
a:hover {
  text-decoration: none;
}
.btn-check:focus+.btn, .btn:focus {
    box-shadow: none !important;
}
.row {
  margin: 0;
}

.btn-violet {
  background: var(--violet-color);
  border-color: var(--violet-color);
}

.btn-green {
  background: var(--green-color);
  border-color: var(--green-color);
}

.btn-red {
  background: var(--red-color);
  border-color: var(--red-color);
}

.text-violet {
  color: var(--violet-color);
}

.text-green {
  color: var(--green-color);
}

.text-red {
  color: var(--red-color);
}

.header {
  padding: 50px 0;
  /*background: var(--black);*/
  /*background: url(../img/bg1.png), #000;*/
}

.header__content--block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.navigation {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  background: var(--gray-color-2);
  z-index: 100;
}
.navigation i {
  font-size: 22px;
}
.headerText {
  color: var(--white);
  float: right;
  padding: 12px 0;
}
.headerText p {
  font-size: 18px;
  padding-bottom: 12px;
}
.headerText p span {
  font-size: 20px;
}
.navigation ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 0;
}

.navigation a {
  color: inherit;
}

.navigation .active p {
  color: var(--green-color);
}
.nav-pills {
  background: #fff;
  /*background-image: linear-gradient(to top, #6e3f1c, #926839, #b6955a, #d8c380, #f9f4ab);*/
}
.nav-pills li:first-child {
  border-left: transparent !important;
}
.nav-pills li:last-child {
  border-right: transparent !important;
}
/* TAB STYLES */
#gift-event.nav-pills {
  justify-content: space-evenly;
}

#gift-event.nav-pills .nav-item {
  flex: 1;
  text-align: center;
  border: 2px solid var(--white);
  border-top: transparent;
}
#gift-event.nav-pills .nav-item .nav-link {
  background-image: linear-gradient(to top, #6e3f1c, #926839, #b6955a, #d8c380, #f9f4ab);
}

#gift-event.nav-pills .nav-link {
  font-weight: 500;
  color: #212529;
  border-radius: 0;
  font-size: 18px;
  padding: 10px 0;
}

#gift-event.nav-pills .nav-link.active {
  background: #0000FE;
  color: var(--white);
  border: transparent !important;
}

#gift-eventContent.tab-content {
  /*padding: 0 30px;*/
}

/* COUNTDOWN STYLE */
.countdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.countdown p b {
  font-size: 18px;
}

.countdown p span {
  padding: 2px 10px;
  margin-top: 100px;
  border-radius: 3px;
  background: var(--gray-color-2);
  font-weight: 700;
  font-size: 20px;
}

/* JOIN GAME STYLE */
.join-game {
  padding-bottom: 20px;
  /*border-bottom: 2px solid var(--gray-color-1);*/
}

.join-game--btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.join-game--btns a {
    width: 140px;
    padding: 7px 20px;
    margin: 0 20px;
    font-size: 18px;
}

.game-count {
  width: 90%;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.game-count p {
  font-size: 24px;
  font-weight: 600;
  color: var(--white);
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  border-radius: 50%;
}

.game-count-1 p:nth-child(3),
.game-count-1 p:nth-child(5),
.game-count-2 p:nth-child(2),
.game-count-2 p:nth-child(4) {
  background: var(--red-color);
}

.game-count-1 p:nth-child(2),
.game-count-1 p:nth-child(4),
.game-count-2 p:nth-child(3),
.game-count-2 p:nth-child(5) {
  background: var(--green-color);
}

.game-count p:first-child {
  background: linear-gradient(
    to bottom,
    var(--red-color) 40%,
    var(--violet-color) 60%
  );
}

.game-count:nth-child(3) p:first-child {
  background: linear-gradient(
    to bottom,
    var(--green-color) 40%,
    var(--violet-color) 60%
  );
}

/* GOLD RECORD */
.gold-record {
  margin: 20px 0 100px;
  padding-top: 40px;
}
.gold-record-table {
  border: 1px solid #eee;
}
.gold-record h4 {
  padding: 5% 0 2% 0;
}
#gift-eventContent table thead {
  color: var(--white);
  background: linear-gradient(to right, #e61f71, #5b52a2);
}

#gift-eventContent table th,
#gift-eventContent table td {
  text-align: center;
}

#gift-eventContent table th:first-child,
#gift-eventContent table td:first-child {
  text-align: left;
}

#gift-eventContent table th {
  font-weight: 400;
}

#gift-eventContent table td {
  vertical-align: middle;
}

.result-number {
  font-size: 20px;
  font-weight: 600;
}

.result-circle {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  border-radius: 50%;
}

.app--sidebar {
  background: var(--pink-gradient);
}

.menu-list {
  color: var(--white);
  padding: 15px;
}

.menu-list:hover {
  background: var(--violet-color);
}

.menu-item i {
  margin-right: 10px;
}

.accordion-button:not(.collapsed) {
  color: var(--white);
  background-color: var(--violet-color);
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  /*background-image: url(../img/accordion-dropdown-icon.svg);*/
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-item:last-child {
  margin-bottom: 100px;
}

.accordion-body {
  color: var(--white);
}

@media screen and (max-width: 1024px) {
  .game-count {
    /*width: 70%;*/
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 40px 0;
    text-align: center;
  }
  .header img {
    width: 80%;
  }
  .headerText {
    float: initial;
    text-align: center;
  }
  .app {
    min-height: max-content;
  }

  .header__content--block img {
    width: 100%;
    height: auto;
  }

  #gift-eventContent.tab-content {
    /*padding: 0 10px;*/
  }  
  .join-game--btns a {
    font-size: 15px;
    width: 33.33%;
    padding: 5px 10px;
    margin: 0 5px;
  }

  .game-count {
    width: 95%;
  }

  .game-count p {
    width: 50px;
    height: 50px;
    margin: 0;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 318px) {
  #gift-event.nav-pills .nav-link {
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .nav-link {
    padding: .5rem;
  }
  .countdown p b {
    font-size: 15px;
  }
  #gift-event.nav-pills .nav-link {
    font-size: 14px;
  }
  .countdown p b span {
    padding: 5px;
  }

  .join-game--btns a {
    padding: 4px;
    font-size: 14px;
  }

  .game-count p {
    width: 35px;
    height: 35px;
  }

  .game-count p {
    font-size: 18px;
  }
  .gold-record h4 {
    padding: 0% 0 2% 0;
  }
  td,
  th {
    font-size: 14px;
  }
}

@media screen and (min-width: 698px) and (max-width: 968px) {
  .join-game--btns a {
    width: 125px;
    padding: 8px 0px;
    margin: 0 5px;
    font-size: 14px;
  }
  .game-count {
    width: 100%;
  }
  .game-count p {
    font-size: 22px;
    font-weight: 600;
    color: var(--white);
    width: 50px;
    height: 50px;
    margin: 5px;
    padding: 25px;
  }

}

.timer{
  margin-top: -9px;
}

.eyeic{
  margin-top: -30px;
  background-color: #00B0F2;
}

.withdrawBtn span{
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}
.border-form span{
  display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}